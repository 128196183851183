:root {
        /* Header */
        --header-background: #0A0C0E;
        --header-text-color: gray;
        --header-active-link-color: #ffffff;
        --header-active-btn-bg: #1D2024;
        --header-font-size: 1.25rem;
        --user-menu-color-hover: #010101;
        --user-menu-bg: #f5f5f7;

        /*Body*/
        --background: #0A0C0E;
        --aside-list-item-bg: linear-gradient(180deg, rgba(217, 221, 255, 0.10) 0%, rgba(239, 241, 255, 0.07) 100%);
        --aside-list-active-bg: linear-gradient(180deg, rgba(217, 221, 255, 0.04) 0%, rgba(239, 241, 255, 0.03) 100%);
        --aside-list-active-border-color: #1367E1;
        --aside-list-border-color-hover: #1367E1;
        --btn-bg: #084EFE;
        --btn-bg-hover: #1b5afa;
        --filter-button-bg: #f5f5f7;
        --filter-button-active-bg: $black;
        --text-color: #DCDCDC;

        /*Form*/
        --form-text-color: #DCDCDC;
        --form-secondary-text-color: $black;
        --label-color: gray;
        --header-panel-bg: #EFF1FF12;
        --input-border-color: #EFF1FF12;
        --input-border-color-hover: #EFF1FF12;
        --input-border-color-focus: #1b5afa;
        --input-bg: #EFF1FF12;
        --selected-select-item: #EFF1FF12;
        --toggle-switcher-active-bg: #ffffff;
        --toggle-switcher-border-active-color: #ffffff;
        --toggle-active-bg: #29DA66;
        --toggle-switcher-bg: #dadee2;
        --toggle-switcher-border-color: #dadee2;
        --tab-color: gray;
        --tab-bg: #0A0C0E;
        --tab-active-bg: #1D2024;
        --tab-active-color: #ffffff;
}

.ant-layout-header {
        min-height: 3.5rem !important;
        position: static !important;

        .ant-menu-title-content {
                .active {
                        color: #ffffff !important;
                }
        }
}

main {
        margin-top: 0 !important;
}

main[pagename="urgentTask"] {
        background: var(--background);
}

.ant-list-item {
        a {
                border-color: #EFF1FF12;
        }
}

.formio-component-tabs>.card>.card-header .nav-tabs .nav-item {
        background: #0A0C0E;
}

.formio-component-tabs>.card>.card-header .nav-tabs .nav-item.active {
        background: #1D2024 !important;
}

.formio-component-tabs>.card>.card-header .nav-tabs .nav-item:hover {
        background: #1D2024 !important;
}

.header-panel {
        background: linear-gradient(180deg, rgba(217, 221, 255, 0.10) 0%, rgba(239, 241, 255, 0.07) 100%) !important;

        h4 {
                color: #DCDCDC;
        }

        .input-group-prepend {
                .input-group-text {
                        font-size: 1.5rem;
                        font-weight: 600;
                }
        }

        .formio-component-textarea {
                div {
                        color: #DCDCDC !important;
                }
        }
}

.input-group-append {
        border-color: #EFF1FF12 !important;
}

input.form-control {
        border-color: #EFF1FF12;
        color: #DCDCDC;
}

.formio-component-select {
        .dropdown {
                border-color: #EFF1FF12 !important;
        }
}

.choices__list {
        background: #0A0C0E !important;
        border: none !important;
        color: #DCDCDC;
}

.selection.dropdown {
        background: #EFF1FF12;
        color: #DCDCDC;
}

.selection.dropdown[disabled="disabled"] {
        border: none;
        background: transparent !important;

        .choices__list.choices__list--single {
                background: #0A0C0E !important;
        }
}

tr:hover {
        .selection.dropdown[disabled="disabled"] .choices__list.choices__list--single {
                background: transparent !important;
        }
        .formio-choices.is-disabled .choices__item.choices__item--selectable {
                background: transparent !important;
        }
        .choices__item.choices__item--selectable {
                background: transparent !important;
        }
}

.ant-list-header {
        button {
                background: #084EFE;
        }

        .ant-input-group-addon {
                button {
                        background: #1D2024;

                        svg {
                                path {
                                        stroke: #DCDCDC;
                                }
                        }
                }

        }

        .ant-input-affix-wrapper {
                background: #1D2024 !important;
                border-color: #0A0C0E !important;
        }

        .ant-input {
                color: #DCDCDC;
        }

        .ant-input-prefix {
                svg {
                        path {
                                fill: #DCDCDC;
                        }
                }
        }
}

.ant-popover-arrow {
        display: none;
}

.ant-popover-inner {
        background: #202429;
        box-shadow: 0 0.35rem 0.6rem rgba(0, 0, 0, 0.67) !important;

        .ant-select-selector,
        input {
                border-color: #EFF1FF12 !important;
                color: #DCDCDC !important;
        }
}

.ant-select-dropdown {
        background: #20242a !important;
        box-shadow: 0 0.35rem 0.6rem rgba(0, 0, 0, 0.67) !important;
}


div.rc-virtual-list {
        background: transparent !important;
}

.ant-select {
        color: #DCDCDC !important;
}

.red-panel {
        background: #A02020;

        div {
                background: #A02020 !important;
                font-size: 0.9rem !important;
                color: #DCDCDC !important;
        }

        .choices__list--multiple .choices__item {
                margin-bottom: 0 !important;
                margin-left: 1rem;
        }

        .choices__item,
        .choices__item--selectable,
        .disabledValueContainer,
        .col-form-label {
                color: #DCDCDC !important;
        }

        .choices__list.choices__list--multiple {
                .choices__item {
                        color: #DCDCDC !important;
                        font-size: 0.9rem !important;
                        margin-left: 0 !important;
                }
        }

        .choices__item.choices__item--selectable {
                background: #A02020 !important;
        }

        .selection.dropdown[disabled="disabled"] .choices__list.choices__list--single {
                background: #A02020 !important;
        }
}

p {
        color: #DCDCDC !important;

}

.formio-component-datagrid .datagrid-table tbody tr:hover td {
        background: #EFF1FF12;
}

.choices__item.choices__item--selectable {
        background: #EFF1FF12;
}

.formio-choices.is-disabled {
        .choices__item.choices__item--selectable {
                background: #0A0C0E;
        }
}

.btn-default {
        color: #DCDCDC;

        &:hover {
                color: #ffffff;
        }
}

.formio-component-select .choices__list--multiple .choices__item {
        background: #084EFE;
        border-color: #084EFE;
}

.datagrid-table {
        button {
                background: #084EFE;
                border-color: #084EFE;
                color: #DCDCDC;
        }
}

textarea {
        background: #EFF1FF12 !important;
        margin-top: 0.6rem;
        border-color: #EFF1FF12 !important;
        color: #DCDCDC !important;
}

textarea:focus {
        border-color: #084EFE;
}

.formio-component-panel:has(.formio-component-callResult)+.formio-component-submit {
        margin-top: 1.5rem;
        padding-left: 0 !important;
}

.formio-component-parameters\.termOptions {
        .form-group {
                margin-bottom: 0 !important;
        }

        .datagrid-table {

                .disabledValueContainer,
                input {
                        background: #EFF1FF12 !important;
                }

                .formio-component-number {
                        margin-bottom: 0 !important;
                        margin-right: 1.2rem;

                        label:after {
                                display: none;
                        }

                        .disabledValueContainer,
                        input {
                                padding: 0.7rem;
                                border-radius: 0.35rem;
                        }
                }

                td {
                        padding: 0.4rem 0;
                }

                td,
                tr:hover {
                        background: transparent !important;
                }

                tfoot {
                        margin-top: 1.5rem !important;
                }
        }
}

.formio-component-parameters\.termSelectionMode {
        &+.formio-component-columns {
                div {
                        padding: 0;
                }
        }

        .form-radio {
                margin: 1rem 0 0 0 !important;
        }
}

.ant-pagination-jump-prev,
.ant-pagination-jump-next,
.ant-pagination-prev,
.ant-pagination-next {
        background: #EFF1FF12 !important;

        .ant-pagination-item-link {
                width: 100%;
                background: #EFF1FF12 !important;
        }

        .ant-pagination-item-ellipsis {
                color: #DCDCDC !important;
                letter-spacing: normal !important;
                text-indent: inherit !important;
        }
}

.formio-dropdown {
        border: none;
        margin-top: 0.5rem;
        border-radius: 0.6rem;

        .btn-primary {
                background: #EFF1FF12 !important;
                color: #DCDCDC !important;
        }
}

.formio-dropdown-trigger .formio-dropdown-trigger {
        background: #084EFE !important;

        &:hover {
                background: #084EFE !important;
        }
}

.formio-dropdown .formio-dropdown-menu {
        border: none;
}

h1.ant-typography {
        color: #DCDCDC !important;
}

.ant-picker-panels {
        background: #0a0c0e !important;

        th,
        .ant-picker-cell-inner,
        .ant-picker-header {
                color: #DCDCDC !important;
        }

        .ant-picker-cell-inner::before {
                border-color: #084EFE !important;
        }
}

.ant-picker.ant-picker-range,
.ant-picker.ant-picker-range.ant-picker-focused {
        background: #EFF1FF12 !important;
}


.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        background: #084EFE !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        background: #084EFE !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
        background: #084efe50 !important;

}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
        background: #EFF1FF12 !important;
}

.ant-btn:not([disabled]):active {
        background: #084EFE !important;
        color: #DCDCDC !important;
}

.ant-select-item-option-content {
        color: #DCDCDC !important;
}

.ant-select-selection-item {
        display: flex;
        align-items: center;
        background: #084EFE !important;
        color: #DCDCDC !important;
        padding: 0.0625rem 0.0625rem 0.0625rem 0.5rem !important;
        padding-right: 0.5rem !important;
        max-width: fit-content;
        font-size: 0.7rem !important;
        border: none !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: #EFF1FF12 !important;
}

.ant-form-item-control-input-content {
        position: relative;

        .anticon-close-circle {
                background: transparent !important;
        }

        .ant-select-clear {
                background: transparent;
        }

        .anticon-close-circle {
                position: absolute;
                right: 1.2rem;
                svg {
                        fill: #DCDCDC;
                }
        }
}

.ant-picker-range-arrow {
        display: none !important;
}

.ant-picker-clear {
        background: #EFF1FF12 !important;

        .anticon-close-circle {
                color: #084EFE !important;
        }
}

.ant-dropdown {
        .ant-dropdown-arrow {
                display: none !important;
        }

        .ant-menu-root {
                background: #202429;
                border: none;
        }

        div:hover {
                background: #EFF1FF12 !important;
                color: #DCDCDC !important;

                a:hover {
                        background: #EFF1FF12 !important;
                        color: #DCDCDC !important;
                }
        }

        .active {
                color: #DCDCDC !important;
        }
}

.iti__country-list {
        border-color: #202429;
        background: #202429;
        color: #DCDCDC !important;
        z-index: 15;

        .iti__dial-code {
                color: #DCDCDC !important;
        }
}

main[pagename="tasks"] {
        .ant-spin-container {
                .ant-list-items {
                        .ant-list-item {
                                margin-bottom: 0.9rem;

                                a:hover {
                                        background: #EFF1FF12 !important;
                                }
                        }
                }
        }
}

.ant-pagination-item {
        background: transparent !important;
}

.ant-pagination-item-active {
        background: #EFF1FF12 !important;
}

div:has(> .ant-spin) {
        background: #0A0C0E;
}

.form-control:focus {
        color: #DCDCDC !important;
}

.iti__selected-dial-code {
        color: #DCDCDC !important;
}

.formio-dropdown-menu.open {
        margin-top: 10px !important;
        background: #202429;

        .btn-primary:hover {
                background: #44475712 !important;
                cursor: pointer;
        }

        .formio-dropdown .btn-md {
                background: #202429 !important;
        }
}

.download-file-link {
        display: none !important;
}

.file-remove {
        background-color: #202429 !important;
}

.formio-component-verificationDecision.formio-error-wrapper {
        background-color: transparent;
        border-color: transparent;

        .formio-errors {
                display: none;
        }
}

.formio-component-panel:has(.formio-component-verificationDecision) {
        margin: 0 1.52rem;

        .formio-component-verificationDecision {
                padding: 0;
        }
}

.formio-scoring-component {
        margin-bottom: 0.7rem;
}

.input-as-status {
        white-space: nowrap;
}

.formio-component-select.formio-component-reason {
        margin-bottom: 0.6rem !important;
}

.input-group-append {
        margin: 0 !important;
        border-left: #EFF1FF12 !important;
        transition: all ease 0.2s;
}

.form-control:active+.input-group-append,
.form-control:focus:not(.is-invalid)+.input-group-append {
        border-color: var(--input-border-color-focus) !important;
}



.form-control:not(.is-invalid):active, input.form-control:not(.is-invalid):focus {
        border-right: 0.2rem solid #084EFE !important;
}

td {
        .formio-component:has(.disabledValueContainer) {
                .input-group {
                        flex-wrap: nowrap;
                }
        }
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
        box-shadow: none;
}

.input-group-prepend {
        transition: all ease 0.2s;
        .input-group-text {
                font-size: 1.2rem;
                transition: all ease 0.2s;
        }
}

.input-group:has(.is-invalid) {
        .input-group-prepend {
                .input-group-text {
                        transition: all ease 0.2s;
                        border: 0.1rem solid #f73743 !important;
                        border-right: none !important;
                }
        }
}

.input-group-prepend {
        margin-right: 0;
}

.form-control {
        transition: all ease 0.2s;
}

.input-group:has(.is-invalid:active) {
        .input-group-prepend {
                .input-group-text {
                        border: 0.2rem solid #f73743 !important;
                        border-right: none !important;
                }
        }
}

.input-group:has(> .input-group-prepend) {
        .form-control {
                border-left: none !important;
        }
}

.input-group-prepend {
        .input-group-text {
                background: var(--input-bg);
                color: var(--label-color);
                border-color: #EFF1FF12;
                border-right: none;
        }
}

.form-control.is-invalid+.input-group-append {
        border-color: #f73743 !important;
}

.form-control.is-invalid:hover {
        border-color: #f73743 !important;
}

.form-control.is-invalid {
        border-right: 0.09rem solid !important;
        border-color: #f73743 !important;
}

// .formio-component:has(>.input-group-append) {
//         .form-control {
//                 border-right: none !important;
//         }

// }

.choices__list--multiple {
        span {
                color: #DCDCDC !important;
        }
}

.formio-dialog-content {
        background: #202429 !important;

        .formio-component-tabs {
                .card {

                        .nav-item.active,
                        .nav-item {
                                background: transparent !important;
                        }

                        .card-header {
                                .nav-item.active {
                                        background: transparent !important;
                                }
                        }
                }
        }
}

.component-edit-container {
        .card-header {
                background: #202429 !important;
        }

        .row:nth-child(2) {
                div:has(> button.btn-success) {
                        .btn-danger {
                                background-color: #f73743;
                                border-color: #f73743;

                                &:hover {
                                        opacity: 0.8;
                                }
                        }

                        .btn-success {
                                background-color: #084EFE;
                                border-color: #084EFE;

                                &:hover {
                                        opacity: 0.8;
                                }
                        }
                }
        }
}

main[pagename="task"] {
        .red-panel {
                .formio-component-content h4 {
                        color: #DCDCDC !important;
                }
        }
}

.datagrid-table-empty-cell {
        color: #DCDCDC !important;
}

.iti__country-list {
        background: #202429;
        border: none;

        .iti__divider {
                display: none;
        }

        .iti__country-name {
                color: #ffffff;
        }

        .iti__country:hover {
                background: var(--input-bg);
        }
}

.choices[data-type*=select-one] .choices__button {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAAAXNSR0IArs4c6QAAAS9JREFUOE+VlbFxwzAMRT+KlNnAd9og2iALxCukyADuUqfxBD7X2SJu3EUb2NnA5w1SukAOMqBjIFIgWRKnd8Dn/xABADM/AvgCsCGis9y1HGZ+BrAFsCaiX0qAUpDTt4AV+K3fDiOYmeXCgFK7AnipATugDTcI9AnAyY0bghUokol06elINRXwAcAqqRbBBeCPanoZoS3gCCisCVoDrgHOoAH4A8DOaTiNnIr6r9NACnaTZYHZTgOwlYvARahK8Qbg03V401c+llKXHV+BEoicD8OAlDTNAaXDhxofz6ALtnlXKcKAeJ/mOpweRSMdJi9N1CKwJXmWfQHa+gptE3UsW6oJWOHjPrdPF42derPQ8bhP7Vdii7ojokvJ2P7e7eP75lejG/i1Beik2Ns/6g9xH8nstPFoAgAAAABJRU5ErkJggg==");
}

.choices[data-type*=select-one]:after {
        border-color: #919396 transparent transparent;
}

.dropdownContainer {
        background: #202429 !important;
        border-color: #202429 !important;

        .btn {
                color: #DCDCDC !important;
                background: #202429 !important;

                &:hover {
                        background: #44475775 !important;
                }
        }
}

.pagination-btn {
        background: transparent !important;
        color: #DCDCDC !important;

        img {
                display: none;
        }

        &:hover {
                background: #eff1ff33 !important;
        }
}

.pagination-btn.prev-btn,
.pagination-btn.next-btn,
.pagination-btn.active {
        position: relative;
        background: #EFF1FF12 !important;

        &:hover {
                background: #eff1ff33 !important;
        }
}

.pagination-btn.prev-btn::before {
        content: '<';
        color: #DCDCDC !important;
}

.pagination-btn.next-btn::after {
        content: '>';
        color: #DCDCDC !important;
}

.input-group:has(.form-control:active,.form-control:focus) {
        .input-group-prepend {
          .input-group-text {
            transition: all ease 0.2s;
            border: 0.2rem solid var(--input-border-color-focus) !important;
            border-right: none !important;
          }
        }
}

.input-group:has(.is-invalid:active,.is-invalid:focus) {
        .input-group-prepend {
                .input-group-text {
                        border: 0.09rem solid #f73743 !important;
                        border-right: none !important;
                }
        }
}

.formio-component-plaid\.agentOnboardingFlow {
        label {
                margin-bottom: 1.5rem;
        }
}

.formio-component-details\.hasDownPayment {
        margin: 1rem 0 !important;
}

.formio-component-details\.downPayment {
        .datagrid-table input {
                padding: 0.7rem !important;
                border-radius: 0.35rem !important;
                background: #EFF1FF12 !important;
        }
        .input-group-append {
                border: none;
        }
        .datagrid-table td {
                vertical-align: baseline;
        }
        .datagrid-table tbody tr:hover td {
                background: transparent;
        }
}

.ant-empty-description {
        color: #DCDCDC !important;
}

.custom-component-loading {
        cursor: wait;
        .choices[data-type*=select-one] {
                pointer-events: none;
        }
}

main[pagename="agents"] {
        .well_content-horizontal + .formio-component-columns {
                p:has(.text-big) {
                        padding: 0 0.94rem 0 1.6rem;
                }
        }
        .well_content-horizontal:has(h2) + div {
                p {
                        padding: 0 0.94rem 0 1.6rem;
                        font-size: 1.2rem;
                        color: var(--label-color) !important;
                        font-weight: 400;
                }
        }
}

.ant-picker-header {
        button {
                color: #DCDCDC !important;

                &:hover {
                        color: #a9a9a9 !important;
                }
        }
}

.formio-component-email.formio-component-userId {
        padding-left: 1.6rem;
}

.formio-component-userId.formio-component-label-hidden + .formio-component-content {
        padding-left: 1.6rem;
}

.formio-component-email.formio-component-userId.input-group-disabled {
        margin-bottom: 0;
}
